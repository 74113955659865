import React, { useEffect } from 'react'
interface IProps {}

const Heikoop: React.FC<IProps> = (props) => {
  return (
    <svg
      id="svg1"
      width="160"
      height="80"
      version="1.1"
      viewBox="0 0 429.37 214.21"
      className="heikoop"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs id="defs1">
        <linearGradient id="swatch456">
          <stop id="stop456" offset={0} />
        </linearGradient>
        <linearGradient
          id="linearGradient456"
          x1="82.286"
          x2="99.022"
          y1="182.7"
          y2="182.7"
          gradientTransform="matrix(3.3212 0 0 4.8012 83.872 -517.88)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#swatch456"
        />
        <linearGradient
          id="linearGradient457"
          x1="82.286"
          x2="99.022"
          y1="182.7"
          y2="182.7"
          gradientTransform="matrix(3.2969 0 0 4.8017 145.98 -517.96)"
          gradientUnits="userSpaceOnUse"
          xlinkHref="#swatch456"
        />
      </defs>
      <g id="g1" transform="translate(-68.78 -221.45)">
        <g id="group-R5">
          <g>
            <path
              id="path45"
              d="m498.09 271.26h-429.24v-49.741h429.24z"
              fill="#be0019"
              stroke="#fff"
              strokeWidth=".133"
            />
            <path id="path46" d="m491.26 434.97h-415.59v-163.71h415.59z" />
            <path
              id="yellow-bg"
              d="m475.74 435.67h-121.51v-152.7h121.51z"
              fill="#ff0"
              className="deur-flicker"
            />
          </g>
          <rect
            id="deur-r"
            x="357.15"
            y="285.66"
            width="55.583"
            height="147.31"
            stroke="url(#linearGradient456)"
            strokeWidth="3.9932"
          />
          <rect
            id="deur-l"
            x="417.26"
            y="285.65"
            width="55.176"
            height="147.33"
            stroke="url(#linearGradient457)"
            strokeWidth="3.9788"
          />
        </g>
        <g
          id="g456"
          transform="translate(-2.9637 -.2615)"
          fill="#fff"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={2}
        >
          <path id="path3" d="m347.45 237.44 7.1477 20.571" />
          <path id="path4" d="m354.81 257.96 93.312-0.49309" />
          <path id="path2" d="m340.29 237.31 6.9605 0.14442" />
          <path
            id="path14"
            transform="translate(2.9637 .2615)"
            d="m369.76 252.61h-19.613"
          />
          <path
            id="path15"
            transform="translate(2.9637 .2615)"
            d="m372.81 239.62-2.9637 12.988"
          />
          <path
            id="path16"
            transform="translate(2.9637 .2615)"
            d="m348.58 239.8 24.145-0.2615"
          />
          <path
            id="path17"
            transform="translate(2.9637 .2615)"
            d="m339.69 247.03 12.639-0.0872"
          />
          <path
            id="path20"
            transform="translate(2.9637 .2615)"
            d="m334.9 242.15h7.4092"
          />
          <path
            id="path21"
            transform="translate(2.9637 .2615)"
            d="m334.9 252h9.5012"
          />
          <path
            id="path22"
            transform="translate(2.9637 .2615)"
            d="m351.46 233h6.0145"
          />
          <path
            id="path23"
            transform="translate(2.7893 .2615)"
            d="m357.82 237.09v-3.9225"
          />
          <path
            id="path24"
            transform="translate(2.7893 .43583)"
            d="m351.72 237.18c-0.0291-1.3947-0.0581-2.7893-0.0872-4.184"
          />
        </g>
        <g fill="#fff" stroke="#fff" strokeLinecap="round" strokeWidth={2}>
          <path id="path25" d="m354.07 228.03h7.1477" />
          <path id="path27" d="m353.9 232.91v-4.707" />
          <path id="path28" d="m361.22 231.69v-3.3995" />
        </g>
        <g fill="none" strokeLinecap="round">
          <g stroke="#fff" strokeWidth={2}>
            <path
              id="path29"
              d="m361.22 237.09 7.1477-11.68s5.1426-3.5233 5.4044 2.9637c-0.61017 1.6562-5.0557 8.8038-5.0557 8.8038"
            />
            <circle id="path30" cx="356.87" cy="264.17" r="2.8353" />
            <circle id="circle30" cx={366} cy="264.17" r="2.8353" />
          </g>
          <text
            id="text30"
            x="376.72098"
            y="253.20186"
            fontSize="21.333px"
            letterSpacing="2px"
            stroke="#ffffff"
            style={{ lineHeight: '0.1' }}
            xmlSpace="preserve"
          >
            <tspan
              id="tspan30"
              x="376.72098"
              y="253.20186"
              fill="#ffffff"
              fontFamily="'Odin Rounded'"
              fontSize="21.333px"
              fontStyle="italic"
              fontWeight="bold"
              letterSpacing="2px"
              stroke="none"
              style={{ lineHeight: '0.1' }}
            >
              Heikoop
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}

export default Heikoop
