import React, { useEffect, useState } from 'react'

import witch from '../../Assets/Images/witch-img.png'
import BaseScreen from '../../Components/BaseScreen'
import { ReplaceUmbracoConstants } from '../../Helpers/UmbracoHelpers'
import {
  useGetContentItemByPath,
  useGetContentQuery
} from '../../Hooks/UmbracoHooks'
import { IAanmelden, IRoot } from '../../Models/Umbraco'
import { IFaqQueryResult } from '../../Models/Umbraco/IFaqQueryResult'
import { IFaqQuestionsResult } from '../../Models/Umbraco/IFaqQuestionsResult'

interface IProps {}

const Faq: React.FC<IProps> = (props) => {
  const { result: sponsors, isLoading } =
    useGetContentItemByPath<IAanmelden>('/sponsors')

  if (isLoading) {
    return null
  }

  return (
    <>
      <BaseScreen>
        <div
          className="container shadow p-3 mb-7 rounded "
          dangerouslySetInnerHTML={{
            __html: sponsors.properties.contentBlock.markup
          }}
        />
      </BaseScreen>
    </>
  )
}

export default Faq
